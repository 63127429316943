<template>
  <!-- 评论列表 -->
  <div class="comment-wrap">
    <!-- 输入框 -->
    <div v-show="isLogin&&!replaceId" class="input_wrap">
      <div class="user_img">
        <img :src="userinfo.userimg" alt="" />
      </div>
      <div class="input_data">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          maxlength="300"
          v-model="commentData"
        >
        </el-input>
      </div>
      <div class="input_but" @click="sebmitInput">发起评价</div>
    </div>
    <div v-show="!isLogin" class="notlogin">
      <div class="text">您还没有登录，无法发表评论</div>
      <div class="but" @click="goToLogin">立即登录</div>
    </div>
    <!-- 评论区 -->
    <div class="fool-list" infinite-scroll-distance='15' v-infinite-scroll="getData">
      <div class="user-item" v-for="item in moment" :key="item.id">
        <div class="user-image">
          <img :src="item.userimg" mode="" />
        </div>
        <div class="user-main">
          <div class="name">
            {{ item.username }}
            <div
              v-show="isLogin && replaceId !== item.id && userinfo.username!=item.username"
              class="reply_one"
              @click="handlereplace(item.id)"
            >
              回复
            </div>
          </div>
          <div class="html-data">{{ item.data }}</div>
          <div class="date-wrap">
            <div class="date">{{ item.datetime }}</div>
            <div class="option"></div>
          </div>
          <div class="comment" v-show="item.pl_data[0]">
            <div
              class="pl-item"
              v-for="(item1, index1) in item.pl_data"
              :key="index1"
            >
              <div class="author">{{ item1.user }}</div>
              <div class="author-data">
                {{ item1.data }} &nbsp;&nbsp;
                <!-- <span @click="handlereplace(item.id)" class="reply">回复</span> -->
              </div>
            </div>
          </div>
          <!-- 回复评论 -->
          <div v-if="replaceId === item.id" class="replace_two" >
            <div class="user_img">
              <img :src="userinfo.userimg" alt="" />
            </div>
            <div class="input_data">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="commentData"
              >
              </el-input>
            </div>
            <div class="input_but" @click="sebmitInput">发起评价</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myStore from "../utils/localStore";

export default {
  name: "comment",
  props: ["moment", "sendComment", "updateComment"],
  data() {
    return {
      isLogin: false, // 是否登录
      userinfo: {},
      commentData: "", // 评论数据
      backId:false, // 是否为楼层回复
      replaceId: null, // 回复楼层评论的id
    };
  },
  mounted() {
    this.userinfo = myStore.getStore("userinfo") || {};
    if (this.userinfo["id"]) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }

    // this.getData()
  },
  methods: {
    // 获取评论数据
    getData() {
      this.updateComment();
    },

    // 点击确定
    sebmitInput() {
      //
      if (this.commentData.length < 1) return;
      this.sendComment(this.commentData, this.replaceId);
      // 恢复
      this.replaceId = null
      this.commentData = "";
    },

    // 点击回复
    handlereplace(id) {
      this.replaceId = id;
    },

    // 去登录
    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang='less'>
// 视频评论
.comment-wrap {
  margin-top: 15px;
  // 输入框
  .input_wrap {
    display: flex;
    //   justify-content: flex-start;
    .user_img {
      width: 10%;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background: #dadada;
      }
    }

    .input_data {
      width: 80%;
      padding: 0 10px;
    }

    .input_but {
      width: 10%;
      margin-top: 15px;
      height: 30px;
      border-radius: 10px;
      background: #3d5aeb;
      font-size: 14px;
      color: #fff;
      text-align: center;
      line-height: 30px;
    }
    .input_but:hover {
      cursor: pointer;
    }
  }
  //未登录
  .notlogin {
    display: flex;
    align-items: center;
    .text {
    }
    .but {
      margin-left: 20px;
      background: #3d5aeb;
      color: #fff;
      height: 32px;
      width: 70px;
      border-radius: 10px;
      line-height: 32px;
      text-align: center;
    }
  }

  // 评论列表
  .fool-list {
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-top: 20px;

    .user-item {
      padding: 10px 20px;
      display: flex;
      .user-image {
        flex: 1;
        padding-right: 30px;
        img {
          float: right;
          width: 44px;
          height: 44px;
          border-radius: 50%;
          background: #dadada;
        }
      }

      .user-main {
        flex: 8;
        .name {
          position: relative;
          height: 44px;
          text-align: left;
          font-size: 15px;
          line-height: 44px;

          .reply_one {
            position: absolute;
            top: 0;
            right: 10px;
            height: 100%;
            width: 40px;
            font-size: 14px;
            font-weight: 700;
            color: #3d5aeb;
          }
        }

        .html-data {
          width: 100%;
          margin-bottom: 20px;
        }

        .date-wrap {
          font-size: 10px;
          color: rgb(126, 126, 126);
        }

        .comment {
          padding: 10px;
          width: 100%;
          background: #f6f5f8;

          .pl-item {
            display: flex;

            .author {
              width: 40px;
              padding-right: 5px;
              color: #3e5ae8;
              line-height: 22px;
              white-space: nowrap;
            }

            .author-data {
              line-height: 22px;
              font-size: 12px;
            }
            .reply {
              display: line;
              color: #3e5ae8;
            }
            // .pl-item:hover .reply{
            //     display: block;
            // }
          }
        }

        // 回复楼层数据
        .replace_two {
          display: flex;

          .user_img {
            width: 10%;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 44px;
              height: 44px;
              border-radius: 50%;
            }
          }

          .input_data {
            width: 80%;
            padding: 0 10px;
          }

          .input_but {
            width: 10%;
            margin-top: 15px;
            height: 30px;
            border-radius: 10px;
            background: #3d5aeb;
            font-size: 14px;
            color: #fff;
            text-align: center;
            line-height: 30px;
          }
          .input_but:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

// 修改ui组件
.el-textarea__inner{
  color: #333 !important;
}
</style>