<template>
  <div
    class="fool-list"
    infinite-scroll-distance="30"
    v-infinite-scroll="getPersonShare"
  >
    <div class="user-item" v-for="(item, index) in personList" :key="index">
      <div class="user-image">
        <img :src="item.userimg" mode="" />
      </div>
      <div class="user-main">
        <div class="name">
          {{ item.username }}
        </div>
        <div class="html-data" v-html="item.data"></div>
        <div class="date-wrap">
          <div class="date">{{ item.datetime | getDateTime }}</div>
          <div class="option"></div>
        </div>
        <div class="operation">
          <div
            @click="butHandle(item, 'isLive', 1)"
            :class="item.isLive === 1 ? 'live-opt active-color' : 'live-opt'"
          >
            <i class="iconfont icon-dianzan_kuai"></i>&nbsp; {{ item.live_num }}
          </div>
          <div @click="showCommentList(item.id)" class="comment-opt">
            <i class="iconfont icon-pinglun1-copy"></i>&nbsp;
            {{ item.comment_num }}
          </div>
          <div
            @click="butHandle(item, 'isCollect', 1)"
            :class="
              item.isCollect === 1 ? 'collect-opt active-color' : 'live-opt'
            "
          >
            <i class="iconfont icon-shoucang"></i>&nbsp; {{ item.collect_num }}
          </div>
        </div>
        <commentView
          v-if="showCommentId == item.id"
          :updateComment="getVideoComment"
          :sendComment="sebmitInput"
          :moment="moment"
        />
      </div>
    </div>
  </div>
</template>

<script>
import commentView from "./../../../components/comment.vue";
import formatDate from "../../../utils/formatDate";
import myStore from "../../../utils/localStore";

let timer = null; // 防抖

export default {
  name: "person",
  components: { commentView },
  data() {
    return {
      userinfo: {},
      per_query: {},
      personList: [],
      moment: [], // 评论数据
      loading: true,
      commentLoading: false, // 获取评论数据中
      // 文章查询
      personQuery: {
        pagesize: 3,
        pagenum: 1,
        total: 0,
        maxpage: 5,
      },
      // 评论查询
      query: {
        pagesize: 10,
        pagenum: 1,
        total: 0,
        maxpage: 5,
      },
      showCommentId: null, // 展开评论的ID
      replaceId: null, // 回复楼层评论的id
      commentData: "", // 评价的文本
      barTop: 0,
      animationData: {},
      // isLive: 0, // 喜欢
      // isCollect: 0, // 是否收藏
      // isComment: 0, // 是否评论
    };
  },
  beforeMount() {
    this.userinfo = myStore.getStore("userinfo");
    if (this.$store.state.person.personList) {
      this.personList = this.$store.state.person.personList
      this.personQuery =  this.$store.state.person.personQuery
      this.loading = false
    } else {
      this.loading = false
      this.getPersonShare();
    }
    // this.getVideoComment()
  },
  filters: {
    getDateTime(date) {
      return formatDate.format1(date);
    },
  },
  methods: {
    async getPersonShare() {
      if (this.personQuery.maxpage < this.personQuery.pagenum) return;
      if (this.loading) return;
      let url = "";
      this.loading = true;
      if (this.userinfo["id"]) {
        url = `person_share/?uid=${this.userinfo["id"]}&pagesize=${this.personQuery.pagesize}&pagenum=${this.personQuery.pagenum}`;
      } else {
        url = `person_share/?pagesize=${this.personQuery.pagesize}&pagenum=${this.personQuery.pagenum}`;
      }
      const { data: res } = await this.$http.get(url);
      if (res.code !== "200") return;
      // 预处理
      // res.data.forEach((v) => {
      //   (v["isLive"] = 0), (v["isCollect"] = 0), (v["isComment"] = 0);
      // });

      this.personList = [...this.personList, ...res.data];
      this.loading = false;
      this.personQuery.pagenum += 1;

      // 保存数据
      this.$store.dispatch('person/saveLocalData', 
      {personList:this.personList,personQuery:this.personQuery } 
      )

      //  this.moment.forEach(v=>{
      //    v.data = v.data.replace(/(\\")|("\\)/, '')
      //  })
    },

    // 展开评论
    showCommentList(id) {
      if (this.showCommentId == id) {
        this.showCommentId = null;
        return;
      }
      this.showCommentId = id;
      // 重置评论查询数据
      this.query = {
        pagesize: 10,
        pagenum: 1,
        total: 0,
        maxpage: 5,
      };
      // 重置评论列表
      this.moment = [];
    },

    // 获取评价
    async getVideoComment() {
      // 判断是否为最后
      if (this.query.maxpage < this.query.pagenum) return;
      if (this.commentLoading) return;
      this.commentLoading = true;

      let { data: res } = await this.$http.get(
        `share_comment/?id=${this.showCommentId}&pagesize=${this.query.pagesize}&pagenum=${this.query.pagenum}`
      );
      if (res.code !== "200") return;
      this.commentLoading = false;
      // 格式化
      res.data.list.forEach((v) => {
        v.datetime = formatDate.format1(v.datetime);
      });
      this.moment = [...this.moment, ...res.data.list];
      this.query.total = res.data.total;
      this.query.maxpage = res.data.maxpage;

      //  为下一次刷新准备
      this.query.pagenum += 1;
    },

    // 发起评价·
    async sebmitInput(form, replace) {
      const form_data = {
        id: this.showCommentId,
        user_id: this.userinfo.id,
        data: form,
      };
      if (replace) {
        form_data["parent"] = replace;
      }
      const { data: res } = await this.$http.post("share_comment/", form_data);
      if (res.code !== "200") {
        console.log(res.code);
        // token过期
        this.$notify({
          title: "登录过期",
          message: "请退出并重新登录",
        });
        return
      }
      // 渲染
      if (replace) {
        // 回复楼层
        this.moment.forEach((v) => {
          if (v.id === replace) {
            v.pl_data.unshift({ user: this.userinfo.username, data: form });
          }
        });
      } else {
        // 回复文章
        res.data.datetime = formatDate.format1(res.data.datetime);
        this.moment.unshift(res.data);
      }
    },

    // 点击按钮的
    butHandle(item, types, num) {
      // 判断是否登录
      if (!this.userinfo.id) return alert("还没有登录");
      if (item[types] === 1) {
        item[types] = 0;
      } else {
        item[types] = 1;
      }

      //  页面渲染
      switch (types) {
        case "isLive":
          if (item["isLive"] == 1) {
            item.live_num += 1;
          } else {
            item.live_num -= 1;
          }
          break;
        case "isCollect":
          if (item["isCollect"] == 1) {
            item.collect_num += 1;
          } else {
            item.collect_num -= 1;
          }
          break;
        case "isComment":
          if (item["isComment"] == 1) {
            item.comment_num += 1;
          } else {
            item.comment_num -= 1;
          }
          break;
        default:
          break;
      }

      // 防止连续操作
      clearTimeout(timer);
      timer = setTimeout(async () => {
        // 发送给服务器
        let form_data = {
          user: this.userinfo.id,
          id: item.id,
          live_num: item["isLive"],
          collect_num: item["isCollect"],
        };
        console.log(item);
        const { data: res } = await this.$http.post("person_share/", form_data);
        if (res.code !== "200") return;
      }, 1500);
    },

    //
    test() {},
  },
};
</script>

<style lang='less' scoped >
.fool-list {
  display: flex;
  flex-direction: column;

  .user-item {
    padding: 10px 20px;
    display: flex;
    .user-image {
      flex: 1;
      img {
        margin-left: 20px;
        width: 66px;
        height: 66px;
        border-radius: 50%;
        background: #dadada;
      }
    }

    .user-main {
      flex: 8;
      .name {
        height: 66px;
        text-align: left;
        font-size: 17px;
        line-height: 66px;
        position: relative;

        .reples {
          position: absolute;
          top: 0;
          right: 30px;
          color: #3d59ef;
          font-size: 14px;
        }
      }

      .html-data {
        width: 100%;
        margin-bottom: 20px;
      }

      .date-wrap {
        font-size: 10px;
        color: rgb(126, 126, 126);
      }

      // 操作区域
      .operation {
        margin-top: 10px;
        width: 100%;
        height: 30px;

        div {
          float: left;
          height: 20px;
          margin-right: 18px;
          font-size: 14px;
          color: #888888;
          cursor: pointer; // 显示手指

          i {
            font-size: 22px !important;
          }
        }

        .active-color {
          color: #3d59ef;
        }
      }
    }
  }
}
</style>